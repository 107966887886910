<template>
  <b-modal
    v-model="show"
    title="INITIAL PAYMENTS"
    title-class="h2 text-white"
    size="lg"
    modal-class="modal-primary"
    body-class="px-0"
    hide-footer
    @hidden="close()"
  >
    <b-container class="px-3">
      <b-row class="mt-1">
        <b-col md="4">
          <custom-input-group label="MODULE" value="CRM" />
        </b-col>
        <b-col md="4">
          <custom-input-group label="YEAR" :value="year" />
        </b-col>
        <b-col md="4">
          <custom-input-group
            label="MONTH"
            :value="(month + '/01/' + year) | myMonth"
          />
        </b-col>

        <b-col md="7" class="mt-1">
          <b-pagination
            v-model="paginate.currentPage"
            :total-rows="totalRows"
            :per-page="paginate.perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            @input="getReportDetails"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
        <b-col md="5" class="mt-1">
          <div
            class="
              d-flex
              align-items-center
              justify-content-end
              align-items-center
            "
          >
            <b-input-group>
              <b-form-input v-model="campo1" @keyup.enter="getReportDetails" />
              <b-input-group-append>
                <b-button variant="primary" @click="getReportDetails">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-table
      :fields="table.fields"
      :items="data"
      small
      responsive
      class="text-center mt-1"
      sticky-header="350px"
    >
    <template #cell(amount)="data">
      <span>
        {{ '$' + data.item.amount }}
      </span>
    </template>
  
    
    </b-table>

    <b-container class="px-3">
      <b-row class="mt-1">
        <b-col md="6">
          <custom-input-group label="SUB TOTAL" :value="'$ ' + subtotal" />
        </b-col>
        <b-col md="6">
          <custom-input-group label="TOTAL" :value="'$ ' + total" />
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
// Components
import CustomInputGroup from "../../../components/CustomInputGroup.vue";

// Services
import MaReportsService from "@/views/commons/components/reports/reports.service";

export default {
  props: ["module_id", "type", "year", "month"],
  components: {
    CustomInputGroup,
  },
  data() {
    return {
      show: false,

      table: {
        fields: [
          { key: "client_name", label: "CLIENT", tdClass: "text-left" },
          { key: "amount", label: "AMOUNT" },
          { key: "settlement_date", label: "DATE" },
          { key: "user_name", label: "REQUEST BY" },
        ],
      },

      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      perPageOptions: [10, 25, 50, 100],
      totalRows: 10,

      data: [],
      campo1: "",
      subtotal: 0,
      total: 0,
    };
  },
  methods: {
    async getReportDetails() {
      try {
        this.data = [];

        this.addPreloader();

        const response = await MaReportsService.getGlobalIncomeReportDetails({
          module_id: this.module_id,
          name_text: this.campo1,
          year: this.year,
          month: this.month,
          type: this.type,
          page: this.paginate.currentPage,
          per_page: this.paginate.perPage,
        });

        if (response.status == 200) {
          this.data = response.data.data;
          this.totalRows = response.data.total;
          var subt = 0;

          this.data.map((item) => {
            subt = (Number(subt) + Number(item.amount)).toFixed(2);
          });

          this.subtotal = subt;
          this.total = this.data[0].total;
        }

        this.removePreloader();
      } catch (error) {
        this.removePreloader();

        throw error;
      }
    },
    close() {
      this.$emit("onClose");
    },
  },
  created() {
    this.getReportDetails();

    this.show = true;
  },
};
</script>

<style>
</style>