<template>
  <div>
    <b-container class="mt-3 mb-2">
      <b-row class="mt-2"  >
        
        <b-col md="3" v-if="value_progress != 0 && isProgress">
          <div class="w-100">
            <div class="d-flex justify-content-between"> 
                <div class="letter-holder">
                    <div>Generating...</div>
                    <!-- <div class="l-1 letter">G</div>
                    <div class="l-2 letter">e</div>
                    <div class="l-3 letter">n</div>
                    <div class="l-4 letter">e</div>
                    <div class="l-5 letter">r</div>
                    <div class="l-6 letter">a</div>
                    <div class="l-7 letter">t</div>
                    <div class="l-8 letter">i</div>
                    <div class="l-9 letter">n</div>
                    <div class="l-10 letter">g</div>
                    <div class="l-11 letter">.</div>
                    <div class="l-12 letter">.</div>
                    <div class="l-13 letter">.</div> -->
                </div>
              <span class="text-center">{{`${(value_progress).toFixed(2)}%`}}</span>
            </div>
            
            <b-progress :max="100" >
              <b-progress-bar :value="value_progress" animated></b-progress-bar>
            </b-progress>
          </div>
        </b-col> 
          <b-col md="1" v-if="(new Date() < dateDeploymentReport)" class="text-center">
          <span class="text-info" >
            Available From {{dateDeploymentReport | myGlobal}}
          </span>
          </b-col>
          <b-col md="1"  v-if="!(value_progress != 0 && isProgress)">

            <b-button
              ref="button_refresh"
              variant="success"
              class="btn-icon mr-1 d-flex"
              style="white-space: nowrap"
              :disabled="timerActive || isLoadingRequest || !(!isProgress && isCreated) || (new Date() < dateDeploymentReport)"
              @click="generateReport"
            > 
                <template v-if="isLoadingRequest">
                  <b-spinner small variant="light" />
                </template>
                <template v-else>
                  <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                  Refresh
                </template> 
              <!-- <template v-else> {{ minutesTime }} : {{ secondsTime }} </template> -->
            </b-button>

          </b-col> 
          <b-col md="2" v-if="!(value_progress != 0 && isProgress)">
            <v-select
              v-model="year"
              :options="years"
              :reduce="(el) => el.value"
              @input="search()"
            />
          </b-col> 
        
        <b-col md="4">
          <p style="margin-top: 7px">
            Last Update: {{ updated_at | myGlobalDay }}
          </p>
        </b-col>
        <b-col md="4" class="text-right">
          <b-button variant="outline-primary" class="mr-1" @click="control(0)">
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
          <b-button variant="outline-primary" @click="control(1)">
            <feather-icon icon="ArrowRightIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <b-table-simple small style="white-space: nowrap"  v-if="!isProgress && isCreated">
      <b-thead>
        <b-tr class="text-center">
          <b-th
            rowspan="2"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                height: 43px;
              "
            >
              PROGRAMS
            </div>
          </b-th>
          <b-th
            v-if="controls == 0"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            colspan="2"
            >JAN</b-th
          >
          <b-th
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            v-if="controls == 0"
            colspan="2"
          >
            FEB
          </b-th>
          <b-th
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            v-if="controls == 0"
            colspan="2"
          >
            MAR
          </b-th>
          <b-th
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            v-if="controls == 0"
            colspan="2"
          >
            APR
          </b-th>
          <b-th
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            v-if="controls == 0"
            colspan="2"
          >
            MAY
          </b-th>
          <b-th
            v-if="controls == 0"
            colspan="2"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
          >
            JUN
          </b-th>
          <b-th
            v-if="controls == 1"
            colspan="2"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
          >
            JUL
          </b-th>
          <b-th
            v-if="controls == 1"
            colspan="2"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
          >
            AUG
          </b-th>
          <b-th
            v-if="controls == 1"
            colspan="2"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
          >
            SEP
          </b-th>
          <b-th
            v-if="controls == 1"
            colspan="2"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
          >
            OCT
          </b-th>
          <b-th
            v-if="controls == 1"
            colspan="2"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
          >
            NOV
          </b-th>
          <b-th
            v-if="controls == 1"
            colspan="2"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
          >
            DEC
          </b-th>
          <b-th
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            colspan="2"
          >
            TOTAL
          </b-th>
        </b-tr>
        <b-tr class="text-center">
          <b-th
            style="width: 125px"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            >PAIDS</b-th
          >
          <b-th
            style="width: 125px"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            >CHARGES</b-th
          >
          <b-th
            style="width: 125px"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            >PAIDS</b-th
          >
          <b-th
            style="width: 125px"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            >CHARGES</b-th
          >
          <b-th
            style="width: 125px"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            >PAIDS</b-th
          >
          <b-th
            style="width: 125px"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            >CHARGES</b-th
          >
          <b-th
            style="width: 125px"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            >PAIDS</b-th
          >
          <b-th
            style="width: 125px"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            >CHARGES</b-th
          >
          <b-th
            style="width: 125px"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            >PAIDS</b-th
          >
          <b-th
            style="width: 125px"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            >CHARGES</b-th
          >
          <b-th
            style="width: 125px"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            >PAIDS</b-th
          >
          <b-th
            style="width: 125px"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            >CHARGES</b-th
          >
          <b-th
            style="width: 125px"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            >PAIDS</b-th
          >
          <b-th
            style="width: 125px"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
            >CHARGES</b-th
          >
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item, index) in programs"
          :key="index + 'ft_first'"
          class="text-center"
        >
          <b-td
            style="width: 135px"
            class="text-left font-weight-bolder"
            :style="
              isDarkSkin
                ? 'border:1px solid #D8D6DE'
                : 'border:1px solid #D8D6DE'
            "
          >
            <span style="font-weight: lighter; font-size: 13px">{{
              item.module_name
            }}</span>
          </b-td>
          <template v-for="(month, monthIndex) in 12">
            <template>
              <b-td
                v-if="controls == (month < 7 ? 0 : 1)"
                :key="monthIndex + '_paids'"
                :style="
                  isDarkSkin
                    ? 'border-left:1px solid #D8D6DE'
                    : 'border-left:1px solid #D8D6DE'
                "
              >
                <b-button
                  v-if="item[months[month][0]] != null && item[months[month][0]] != 0"
                  size="sm"
                  variant="flat-primary"
                  @click="
                    openModalDetailPaids(
                      item.module_id,
                      item.module_name,
                      month
                    )
                  "
                >
                  {{
                    item[months[month][0]] == null
                      ? ""
                      : "$ " + item[months[month][0]]
                  }}
                </b-button>
              </b-td>
              <b-td
                v-if="controls == (month < 7 ? 0 : 1)"
                :key="monthIndex + '_charges'"
              >
                <b-button
                  v-if="item[months[month][1]] != null"
                  size="sm"
                  variant="flat-primary"
                  @click="
                    openModalDetailCharges(
                      item.module_id,
                      item.module_name,
                      month
                    )
                  "
                >
                  {{
                    item[months[month][1]] == null
                      ? ""
                      : "$ " + item[months[month][1]]
                  }}
                </b-button>
              </b-td>
            </template>
          </template>

          <b-td
            :style="
              isDarkSkin
                ? 'border-left:1px solid #D8D6DE'
                : 'border-left:1px solid #D8D6DE'
            "
          >
            {{ item.total_paid == null ? "" : "$ " + item.total_paid }}
          </b-td>
          <b-td>
            {{ item.total_charge == null ? "" : "$ " + item.total_charge }}
          </b-td>
        </b-tr>

        <b-tr
          v-for="(item, index) in programs"
          :key="index + 'ft_second'"
          class="text-center"
        >
          <template v-if="item.module_id == 3">
            <td
              :style="
                isDarkSkin
                  ? 'background:#0065A359;color:white'
                  : 'background:rgba(0, 101, 163, 0.15);color:#006CAE'
              "
            >
              SUB TOTAL
            </td>
            <template v-for="(subTotal, subTotalIndex) in 12">
              <b-td
                :style="
                  isDarkSkin
                    ? 'background:#0065A359'
                    : 'background:rgba(0, 101, 163, 0.15)' +
                      ';color:' +
                      (isDarkTheme ? '#BABABA' : '#006CAE')
                "
                v-if="controls == (subTotal < 7 ? 0 : 1)"
                :key="subTotalIndex + '_sub_paid'"
              >
                {{
                  item[subTotalValues[subTotal][0]] == null
                    ? ""
                    : "$ " + item[subTotalValues[subTotal][0]]
                }}
              </b-td>
              <b-td
                style="color: white"
                :style="
                  isDarkSkin
                    ? 'background:#0065A359'
                    : 'background:rgba(0, 101, 163, 0.15)' +
                      ';color:' +
                      (isDarkTheme ? '#BABABA' : '#006CAE')
                "
                v-if="controls == (subTotal < 7 ? 0 : 1)"
                :key="subTotalIndex + '_sub_charge'"
              >
                {{
                  item[subTotalValues[subTotal][1]] == null
                    ? ""
                    : "$ " + item[subTotalValues[subTotal][1]]
                }}
              </b-td>
            </template>

            <b-td
              :style="
                isDarkSkin
                  ? 'background:#0065A359'
                  : 'background:rgba(0, 101, 163, 0.15)' +
                    ';color:' +
                    (isDarkTheme ? '#BABABA' : '#006CAE')
              "
            >
              {{
                item.sub_total_paid == null ? "" : "$ " + item.sub_total_paid
              }}
            </b-td>
            <b-td
              :style="
                isDarkSkin
                  ? 'background:#0065A359'
                  : 'background:rgba(0, 101, 163, 0.15)' +
                    ';color:' +
                    (isDarkTheme ? '#BABABA' : '#006CAE')
              "
            >
              {{
                item.sub_total_charge == null
                  ? ""
                  : "$ " + item.sub_total_charge
              }}
            </b-td>
          </template>
        </b-tr>

        <b-tr
          v-for="(item, index) in programs"
          :key="index + 'ft_third'"
          :style="[
            'font-size: 17px;',
            'background:' + isDarkTheme ? '#1a2a21' : '#406145',
          ]"
          class="text-center"
          :class="isDarkTheme ? 'td-total-dark' : 'td-total-light'"
        >
          <template v-if="item.module_id == 3">
            <td
              style="color: white"
              :style="isDarkSkin ? 'background:#008FE6' : 'background:#008FE6'"
            >
              <div class="absolute-center">TOTAL</div>
            </td>

            <template v-for="(total, monthTotal) in 12">
              <td
                :style="
                  isDarkSkin
                    ? 'background:#008FE6; color:white;'
                    : 'background:#008FE6;color:white;'
                "
                v-if="controls == (total < 7 ? 0 : 1)"
                :key="monthTotal + '_first_total'"
                colspan="2"
              >
                {{
                  item[totalValues[total]] == null
                    ? ""
                    : "$ " + item[totalValues[total]]
                }}
              </td>
            </template>
            <td
              colspan="2"
              style="color: white; width: 165px"
              :style="isDarkSkin ? 'background:#008FE6' : 'background:#008FE6'"
            >
              {{ item.total == null ? "" : "$ " + item.total }}
            </td>
          </template>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-skeleton-table
      v-else
      :rows="10"
      :columns="10"
      :table-props="{  striped: true }"
    ></b-skeleton-table>

    <b-table-simple small style="white-space: nowrap" class="mt-3">
      <b-thead>
        <b-tr class="text-center">
          <b-th style="width: 150px">CRM</b-th>
          <b-th>IP</b-th>
          <b-th>OTHERS</b-th>
          <b-th>IP</b-th>
          <b-th>OTHERS</b-th>
          <b-th>IP</b-th>
          <b-th>OTHERS</b-th>
          <b-th>IP</b-th>
          <b-th>OTHERS</b-th>
          <b-th>IP</b-th>
          <b-th>OTHERS</b-th>
          <b-th>IP</b-th>
          <b-th>OTHERS</b-th>
          <b-th>IP</b-th>
          <b-th>OTHERS</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item, index) in crm"
          :key="index + 'st_first'"
          class="text-center"
          :class="isDarkTheme ? 'td-sub-total-dark' : 'td-sub-total-light'"
        >
          <td
            style="color: white"
            :style="
              isDarkSkin
                ? 'background:#0065A359'
                : 'background:rgba(0, 101, 163, 0.15)' +
                  ';color:' +
                  (isDarkTheme ? '#BABABA' : '#006CAE')
            "
          >
            SUB TOTAL
          </td>

          <template v-for="(month, monthIndex) in 12">
            <b-td
              :style="
                isDarkSkin
                  ? 'background:#0065A359'
                  : 'background:rgba(0, 101, 163, 0.15)' +
                    ';color:' +
                    (isDarkTheme ? '#BABABA' : '#006CAE')
              "
              v-if="controls == (month < 7 ? 0 : 1)"
              :key="monthIndex + 'crm_paid'"
            >
              <b-button
                v-if="item[months[month][0]]"
                size="sm"
                class="pt-0 pb-0"
                variant="flat-primary"
                @click="openModalReportDetails(item.module_id, 1, month)"
              >
                {{
                  item[months[month][0]] == null
                    ? ""
                    : "$ " + item[months[month][0]]
                }}
              </b-button>
            </b-td>
            <b-td
              :style="
                isDarkSkin
                  ? 'background:#0065A359'
                  : 'background:rgba(0, 101, 163, 0.15)' +
                    ';color:' +
                    (isDarkTheme ? '#BABABA' : '#006CAE')
              "
              v-if="controls == (month < 7 ? 0 : 1)"
              :key="monthIndex + 'crm_charge'"
            >
              <b-button
                v-if="item[months[month][1]]"
                size="sm"
                variant="flat-primary"
                @click="openModalReportDetails(item.module_id, 2, month)"
              >
                {{
                  item[months[month][1]] == null
                    ? ""
                    : "$ " + item[months[month][1]]
                }}
              </b-button>
            </b-td>
          </template>

          <td
            style="color: white"
            :style="
              isDarkSkin
                ? 'background:#0065A359'
                : 'background:rgba(0, 101, 163, 0.15)' +
                  ';color:' +
                  (isDarkTheme ? '#BABABA' : '#006CAE')
            "
          >
            {{ item.total_paid == null ? "" : "$ " + item.total_paid }}
          </td>
          <td
            style="color: white"
            :style="
              isDarkSkin
                ? 'background:#0065A359'
                : 'background:rgba(0, 101, 163, 0.15)' +
                  ';color:' +
                  (isDarkTheme ? '#BABABA' : '#006CAE')
            "
          >
            {{ item.total_charge == null ? "" : "$ " + item.total_charge }}
          </td>
        </b-tr>
        <b-tr
          v-for="(item, index) in crm"
          :key="index + 'st_second'"
          style="font-size: 12px; background: #1a2a21"
          class="text-center"
          :class="isDarkTheme ? 'td-total-dark' : 'td-total-light'"
        >
          <b-td
            style="color: white; width: 150px"
            :style="isDarkSkin ? 'background:#008FE6' : 'background:#008FE6'"
            class="absolute-center"
          >
            TOTAL
          </b-td>

          <template v-for="(total, monthTotal) in 12">
            <td
              style="width: 165px; color: white"
              :style="isDarkSkin ? 'background:#008FE6' : 'background:#008FE6'"
              v-if="controls == (total < 7 ? 0 : 1)"
              :key="monthTotal + '_second_total'"
              colspan="2"
            >
              {{
                item[totalValues[total]] == null
                  ? ""
                  : "$ " + item[totalValues[total]]
              }}
            </td>
          </template>

          <b-td
            style="width: 165px; color: white"
            colspan="2"
            :style="isDarkSkin ? 'background:#008FE6' : 'background:#008FE6'"
          >
            {{ item.total == null ? "" : "$ " + item.total }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <b-table-simple
      small
      style="white-space: nowrap"
      class="mt-3"
      :class="isDarkSkin ? 'bg-warning-lighter-dark' : 'bg-warning-lighter'"
    >
      <tbody>
        <tr
          class="text-center"
          v-for="(item, index) in total"
          :key="index + 'tt_first'"
          style="color: white"
          :style="isDarkSkin ? 'background:#005A90' : 'background:#005A90'"
        >
          <th
            style="color: white; width: 150px"
            :style="isDarkSkin ? 'background:#005A90' : 'background:#005A90'"
          >
            TOTAL
          </th>

          <template v-for="(total, monthTotal) in 12">
            <td
              style="width: 165px"
              v-if="controls == (total < 7 ? 0 : 1)"
              :key="monthTotal + '_third_total' + 1"
              colspan="2"
            >
              {{
                item[totalValues[total]] == null
                  ? ""
                  : "$ " + item[totalValues[total]]
              }}
            </td>
          </template>

          <td colspan="2" style="width: 165px">
            {{ item.total == null ? "" : "$ " + item.total }}
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <modal-report-details
      v-if="showModalReportDetails"
      :module_id="module_id"
      :type="type"
      :year="year"
      :month="month"
      @onClose="closeModalReportDetails"
    />

    <modal-charge-report-details
      v-if="showModalDetailCharges"
      :module_id="module_id"
      :year="year"
      :program_name="module_name"
      :month="month"
      @onClose="closeModalDetailCharges"
    />

    <modal-paids-report-details
      v-if="showModalDetailsPaids"
      :module_id="module_id"
      :year="year"
      :month="month"
      :program_name="module_name"
      @click="closeModalDetailPaids"
      @onClose="closeModalDetailPaids"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
// Components
import vSelect from "vue-select";
import MaReportsService from "@/views/commons/components/reports/reports.service";
import ProgramsTable from "./components/ProgramsTable.vue";
import ModalReportDetails from "./components/ModalReportDetails.vue";
import ModalChargeReportDetails from "./components/ModalChargeReportDetails.vue";
import ModalPaidsReportDetails from "./components/ModalPaidsReportDetails.vue";

// Services

export default {
  components: {
    vSelect,
    ProgramsTable,
    ModalReportDetails,
    ModalChargeReportDetails,
    ModalPaidsReportDetails,
  },
  props: ["global", "modul"],
  data() {
    return {
      years: [],

      months: [
        ["", ""],
        ["p_jan", "c_jan"],
        ["p_feb", "c_feb"],
        ["p_mar", "c_mar"],
        ["p_apr", "c_apr"],
        ["p_may", "c_may"],
        ["p_jun", "c_jun"],
        ["p_jul", "c_jul"],
        ["p_aug", "c_aug"],
        ["p_sep", "c_sep"],
        ["p_oct", "c_oct"],
        ["p_nov", "c_nov"],
        ["p_dec", "c_dec"],
      ],

      subTotalValues: [
        ["", ""],
        ["sub_paid_jan", "sub_charge_jan"],
        ["sub_paid_feb", "sub_charge_feb"],
        ["sub_paid_mar", "sub_charge_mar"],
        ["sub_paid_apr", "sub_charge_apr"],
        ["sub_paid_may", "sub_charge_may"],
        ["sub_paid_jun", "sub_charge_jun"],
        ["sub_paid_jul", "sub_charge_jul"],
        ["sub_paid_aug", "sub_charge_aug"],
        ["sub_paid_sep", "sub_charge_sep"],
        ["sub_paid_oct", "sub_charge_oct"],
        ["sub_paid_nov", "sub_charge_nov"],
        ["sub_paid_dec", "sub_charge_dec"],
      ],

      totalValues: [
        "",
        "total_jan",
        "total_feb",
        "total_mar",
        "total_apr",
        "total_may",
        "total_jun",
        "total_jul",
        "total_aug",
        "total_sep",
        "total_oct",
        "total_nov",
        "total_dec",
      ],

      year: moment().format("YYYY"),
      time1: true,
      pnttime: false,
      tiemposegundos: 30,
      tiempominutos: 0,
      programs: [],
      crm: [],
      total: [],
      controls: 0,
      updated_at: "",
      moduele_id: "",
      modalDetailCharges: false,
      modalDetailPaids: false,
      module_name: "",

      secondsTime: 59,
      minutesTime: 1,
      timerActive: false,
      isLoadingRequest: false,

      // Modals
      showModalReportDetails: false,
      showModalDetailCharges: false,
      showModalDetailsPaids: false,

      // Modals data
      module_id: null,
      type: "",
      month: "",
      isLoading: true,
      value_progress: 0,
      isProgress: false,
      isCreated:false,
      dateDeploymentReport: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin == "dark";
    },
  },
  async created() { 
    this.year_select();
    
    this.isCreated = true;
  },
  async mounted(){
    await this.destroySocket();
    await this.activeSocketChangeProgress();
    await this.search();  
    
  },
  methods: {
    openModalDetailPaids(module_id, module_name, month) {
      this.module_name = module_name;
      this.module_id = module_id;
      this.month = month;
      this.showModalDetailsPaids = true;
    },
    closeModalDetailPaids() {
      this.showModalDetailsPaids = false;
    },
    openModalDetailCharges(module_id, module_name, month) {
      this.module_name = module_name;
      this.module_id = module_id;
      this.month = month;
      this.showModalDetailCharges = true;
    },
    closeModalDetailCharges() {
      this.showModalDetailCharges = false;
    },
    openModalReportDetails(module_id, type, month) {
      this.module_id = module_id;
      this.type = type;
      this.month = month;
      this.showModalReportDetails = true;
    },
    closeModalReportDetails() {
      this.showModalReportDetails = false;
    },
    async generateReport() {
      this.isLoading = true;
      try {
        this.isLoadingRequest = true;
         
        const {data,status} = await MaReportsService.generateReportGlobalIncome({
          year: this.year,
          user_id : this.currentUser.user_id,
        });

        if (status == 200) {
          //this.initTimer();
          this.timerActive = true;
          if(!data.success){ 
            this.showToast("danger", "top-right", data.message, "XIcon"); 
          }
        }

        this.isLoadingRequest = false;
      } catch (error) {
        throw error;
      }
    },
    initTimer() {
      if (this.minutesTime == 0 && this.secondsTime == 0) {
        this.secondsTime = 59;
        this.minutesTime = 1;

        this.timerActive = false;
      } else {
        if (this.secondsTime == 0) {
          this.secondsTime = 59;
          if (this.minutesTime != 0) {
            this.minutesTime -= 1;
          }
        }
        this.secondsTime -= 1;

        setTimeout(() => {
          this.initTimer();
        }, 1000);
      }
    },
    control(type) {
      this.controls = type;
    },
    async search() {
      try {
        this.addPreloader();
        const response = await MaReportsService.getGlobalIncomeReport({
          year: this.year,
        });

        if (response.status == 200) {
          this.controls = response.data[0].controls;
          this.programs = JSON.parse(response.data[0].programs);
          this.crm = JSON.parse(response.data[0].crm);
          this.total = JSON.parse(response.data[0].total);
          this.updated_at = response.data[0].updated_at;
          this.isLoading = false;
        }
      } catch (error) {
        this.removePreloader();
        throw error;
      }finally{
        this.removePreloader();
      }
    },
    year_select() {
      for (let x = 2019; x <= moment().format("YYYY"); x++) {
        this.years.push({ label: x, value: x });
      }
    },
    async activeSocketChangeProgress() {
      try {
        window.socket.subscribe("channel-chat");
        window.socket.bind("global-income-reports-progress-notification", async (response) => {
          const { counter,type,user_id} = response;  
          this.isProgress=true; 
          this.removePreloader();
          if(type == 'report-global-ad'){
            this.value_progress = counter;
            if(this.value_progress >= 100 && !(new Date() < this.dateDeploymentReport) && this.currentUser.user_id == user_id){
              this.showToast("success", "top-right", "Payment report successfully generated!!", "CheckIcon"); 
            }
          }
          if(counter >= 100){ 
            await this.search();
            this.isProgress=false;
          }
        });
      } catch (error) {
        console.log("Socket-binnacle: ", error);
      }
    },
    async destroySocket() {
      return new Promise((resolve, reject) => {
        try {
          window.socket.unbind("global-income-reports-progress-notification");
          resolve(true);
        } catch (error) {
          reject(false);
          console.log(error);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.bg-warning-lighter {
  background: #ffe1c5;
}
.bg-warning-lighter-dark {
  background: #434303 !important;
}
.td-total {
  &-light {
    background: #d4eed4 !important;
    color: #000;
    font-weight: bold;
  }

  &-dark {
    background: #1a2a21 !important;
    color: #000;
    font-weight: bold;
  }
}
.td-sub-total {
  &-light {
    background: #e9e9e9 !important;
    color: #000;
  }

  &-dark {
    background: #1f2321 !important;
    color: #000;
  }
}
.table {
  font-size: 12px;
}
.th-sub-title {
  background: #b5b5b5;
  color: #fff;
  font-weight: normal;
}
.td-total {
  background: #636869;
  color: #fff;
  font-weight: bold !important;
  width: 90px;
}
.td-total-amg {
  background: #ba9127e6;
  color: #fff;
  font-weight: bold;
}


.letter {
  animation-name: loadingF;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-direction: linear;
    float: left;
  font-size: 14px;
  color: #777;
}


.l-1 {
  animation-delay: 0.48s;
}
.l-2 {
  animation-delay: 0.6s;
}
.l-3 {
  animation-delay: 0.72s;
}
.l-4 {
  animation-delay: 0.84s;
}
.l-5 {
  animation-delay: 0.96s;
}
.l-6 {
  animation-delay: 1.08s;
}
.l-7 {
  animation-delay: 1.2s;
}
.l-8 {
  animation-delay: 1.32s;
}
.l-9 {
  animation-delay: 1.44s;
}
.l-10 {
  animation-delay: 1.56s;
}
.l-11 {
  animation-delay: 1.68s;
}
.l-12 {
  animation-delay: 1.70s;
}
.l-13 {
  animation-delay: 1.82s;
}
</style>
