<template>
  <b-modal
    v-model="show"
    title="PAIDS"
    title-class="h2 text-white"
    size="lg"
    modal-class="modal-primary"
    body-class="px-0"
    hide-footer
    @hidden="close()"
  >
    <template v-if="mode == 0">
      <b-container>
        <b-row class="mt-1">
          <b-col md="4">
            <custom-input-group label="MODULE" :value="program_name" />
          </b-col>
          <b-col md="4">
            <custom-input-group label="YEAR" :value="year" />
          </b-col>
          <b-col md="4">
            <custom-input-group
              label="MONTH"
              :value="(month + '/01/' + year) | myMonth"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-table
        small
        :fields="[
          { key: 'user_name', label: 'Advisor', tdClass: 'text-left' },
          { key: 'percent', label: 'Percent' },
          { key: 'amount', label: 'Amount' },
        ]"
        :items="data"
        class="text-center"
        sticky-header="400px"
      >
        <template #cell(percent)="data"> {{ data.item.percent }} % </template>

        <template #cell(amount)="data">
          <b-button
            size="sm"
            variant="flat-primary"
            @click="modeReport(1, data.item.user_id)"
          >
            $ {{ data.item.amount }}
          </b-button>
        </template>
      </b-table>

      <b-container class="px-3">
        <b-row class="mt-2">
          <b-col md="6">
            <custom-input-group label="TOTAL" :value="'$' + totalAmount" />
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-else-if="mode == 1">
      <b-container class="my-1">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-start mb-2"
          >
            <span class="text-muted">
              Showing {{ paginate.startPage }} to {{ paginate.toPage }} of
              {{ totalRows }} entries
            </span>
          </b-col>
          <b-col md="6">
            <b-pagination
              v-model="paginate.currentPage"
              :total-rows="totalRows"
              :per-page="paginate.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              @input="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
          <b-col md="6">
            <div
              class="d-flex align-items-center justify-content-end align-items-center"
            >
              <b-button
                variant="primary"
                class="d-flex mr-1"
                @click="modeReport(0, 0)"
              >
                <feather-icon
                  icon="ChevronsLeftIcon"
                  class="mr-50"
                ></feather-icon>
                Return
              </b-button>
              <b-input-group>
                <b-form-input v-model="campo1" @keyup.enter="search" />
                <b-input-group-append>
                  <b-button variant="primary" @click="search">
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-table
        small
        :fields="[
          { key: 'client', label: 'Client', tdClass: 'text-left' },
          { key: 'amount', label: 'Amount' },
          { key: 'refund_amount', label: 'Partial Refund' },
          { key: 'void_amount', label: 'Partial Void' },
          { key: 'settlement_date', label: 'Date' },
          { key: 'user_name', label: 'Request By', tdClass: 'text-left' },
        ]"
        :items="data"
        class="text-center"
        sticky-header="400px"
      >
        <template #cell(client)="data">
          <div class="d-flex">
            <status-account :account="data.item" :text="false"></status-account>

            <div class="d-block">
              <router-link
                class
                :to="{
                  name: dashboardRouteName,
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                target="_blank"
              >
                {{ data.item.client_name }}
              </router-link>
              <div>{{ data.item.account }}</div>
            </div>
          </div>
        </template>

        <template #cell(amount)="data"> $ {{ data.item.amount }} </template>
        <template #cell(refund_amount)="data">
          <span v-if="data.item.refund_amount" class="text-danger"
            >$ -{{ data.item.refund_amount }}</span
          >
          <span v-else> - </span>
        </template>
        <template #cell(void_amount)="data">
          <span v-if="data.item.void_amount" class="text-danger"
            >$ -{{ data.item.void_amount }}</span
          >
          <span v-else> - </span>
        </template>
      </b-table>

      <b-container class="px-3 mt-2">
        <b-row class="mt-1">
          <b-col md="6">
            <custom-input-group label="SUB TOTAL" :value="'$' + subtotal" />
          </b-col>
          <b-col md="6">
            <custom-input-group label="TOTAL" :value="'$' + totalAmount" />
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

// Components
import CustomInputGroup from "../../../components/CustomInputGroup.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

// Services
import MaReportsService from "@/views/commons/components/reports/reports.service";

export default {
  props: ["module_id", "year", "month", "program_name"],
  components: {
    CustomInputGroup,
    StatusAccount,
  },
  data() {
    return {
      show: false,

      paginate: {
        currentPage: 1,
        perPage: 100,
        startPage: 0,
        toPage: 0,
      },
      perPageOptions: [10, 25, 50, 100],
      totalRows: 10,

      data: [],
      mode: 0,
      start_page: "",
      next_page: "",
      perpage: "",
      last_page: "",
      total_data: "",
      campo1: "",
      user_id: "",
      subtotal: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    totalAmount() {
      if (this.data.length === 0) {
        return 0;
      }

      return this.data[0].total ?? "";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    dashboardRouteName() {
      const routers = {
        2: "dashboard-crm",
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        12: "paragon-dashboard",
        14: "bookeeping-dashboard",
        16: "management-dashboard",
        18: "quality-client-dashboard",
        20: "connection-clients-account",
        21: "ce-customer-service-dashboard",
        22: "ce-customer-service-dashboard",
        25: "specialist-digital-dashboard",
        16: "management-client-dashboard",
      };
      return routers[this.moduleId] || null;
    },
  },
  methods: {
    async modeReport(type, user_id) {
      if (type == 0) {
        this.mode = type;
        await this.getReport();
      } else if (type == 1) {
        this.user_id = user_id;
        this.mode = type;
        await this.resertSearch();
      }
    },
    resertSearch() {
      this.campo1 = "";
      this.search();
    },
    async search() {
      try {
        this.data = [{ total: "" }];

        this.addPreloader();
        const response = await MaReportsService.searchGlobalReportPaidsDetails({
          module_id: this.module_id,
          name_text: this.campo1,
          year: this.year,
          month: this.month,
          user_id: this.user_id,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
        });

        if (response.status == 200) {
          this.data = response.data.data;
          this.paginate.startPage = response.data.from;
          this.paginate.toPage = response.data.to;
          this.totalRows = response.data.total;
          var subt = 0;
          this.data.map((item) => {
            subt = (
              Number(subt) +
              Number(item.amount) -
              Number(item.refund_amount == null ? 0 : item.refund_amount) -
              Number(item.void_amount == null ? 0 : item.void_amount)
            ).toFixed(2);
          });
          this.subtotal = subt;
        }

        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        throw error;
      }
    },
    async getReport() {
      try {
        this.data = [{ total: "" }];

        this.addPreloader();
        const response = await MaReportsService.getGlobalReportPaidsDetails({
          module_id: this.module_id,
          year: this.year,
          month: this.month,
        });

        if (response.status == 200) {
          this.data = response.data.filter((item) => item.amount != null);
        }

        this.removePreloader();
      } catch (error) {
        this.removePreloader();

        throw error;
      }
    },
    close() {
      this.$emit("onClose");
    },
  },
  async created() {
    await this.getReport();

    this.show = true;
  },
};
</script>

<style lang="scss" scoped>
.client-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
  margin-top: 5px;
}

.client-status-hold1 {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid #ffc107;
}
</style>
