<template>
  <b-table-simple
    small
    responsive
    style="white-space: nowrap"
  >
    <b-thead>
      <b-tr
        class="text-center"

      >
        <b-th rowspan="2">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 43px;

            "
          >
            PROGRAMS
          </div>
        </b-th>
        <b-th
          v-if="controls == 0"
          colspan="2"
          style="border: 1px solid; !important;"
        >
          JAN
        </b-th>
        <b-th
          v-if="controls == 0"
          colspan="2"

        >
          FEB
        </b-th>
        <b-th
          v-if="controls == 0"
          colspan="2"
        >
          MAR
        </b-th>
        <b-th
          v-if="controls == 0"
          colspan="2"
        >
          APR
        </b-th>
        <b-th
          v-if="controls == 0"
          colspan="2"
        >
          MAY
        </b-th>
        <b-th
          v-if="controls == 0"
          colspan="2"
        >
          JUN
        </b-th>
        <b-th
          v-if="controls == 1"
          colspan="2"
        >
          JUL
        </b-th>
        <b-th
          v-if="controls == 1"
          colspan="2"
        >
          AUG
        </b-th>
        <b-th
          v-if="controls == 1"
          colspan="2"
        >
          SEP
        </b-th>
        <b-th
          v-if="controls == 1"
          colspan="2"
        >
          OCT
        </b-th>
        <b-th
          v-if="controls == 1"
          colspan="2"
        >
          NOV
        </b-th>
        <b-th
          v-if="controls == 1"
          colspan="2"
        >
          DEC
        </b-th>
        <b-th colspan="2">
          TOTAL
        </b-th>
      </b-tr>
      <b-tr class="text-center">
        <b-th>PAIDS</b-th>
        <b-th>CHARGES</b-th>
        <b-th>PAIDS</b-th>
        <b-th>PAIDS</b-th>
        <b-th>CHARGES</b-th>
        <b-th>PAIDS</b-th>
        <b-th>CHARGES</b-th>
        <b-th>PAIDS</b-th>
        <b-th>CHARGES</b-th>
        <b-th>PAIDS</b-th>
        <b-th>CHARGES</b-th>
        <b-th>PAIDS</b-th>
        <b-th>CHARGES</b-th>
        <b-th>PAIDS</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr
        v-for="(item, index) in programs"
        :key="index + 'ft_first'"
        class="text-center"
      >
        <b-td class="text-left font-weight-bolder">
          {{ item.module_name }}
        </b-td>
        <template v-for="(month, monthIndex) in 12">
          <template>
            <b-td
              v-if="controls == (month < 7 ? 0 : 1)"
              :key="monthIndex + '_paids'"
            >
              <b-button
                size="sm"
                variant="flat-primary"
                @click="
                  openModalDetailPaids(item.module_id, item.module_name, month)
                "
              >
                {{
                  item[months[month][0]] == null
                    ? ""
                    : "$ " + item[months[month][0]]
                }}
              </b-button>
            </b-td>
            <b-td
              v-if="controls == (month < 7 ? 0 : 1)"
              :key="monthIndex + '_charges'"
            >
              <b-button
                size="sm"
                variant="flat-primary"
                @click="
                  openModalDetailCharges(
                    item.module_id,
                    item.module_name,
                    month
                  )
                "
              >
                {{
                  item[months[month][1]] == null
                    ? ""
                    : "$ " + item[months[month][1]]
                }}
              </b-button>
            </b-td>
          </template>
        </template>

        <b-td>
          {{ item.total_paid == null ? "" : "$ " + item.total_paid }}
        </b-td>
        <b-td>
          {{ item.total_charge == null ? "" : "$ " + item.total_charge }}
        </b-td>
      </b-tr>

      <b-tr
        v-for="(item, index) in programs"
        :key="index + 'ft_second'"
        class="text-center"
        style="background: #333333"
      >
        <template v-if="item.module_id == 3">
          <td>SUB TOTAL</td>
          <template v-for="(subTotal, subTotalIndex) in 12">
            <b-td
              v-if="controls == (subTotal < 7 ? 0 : 1)"
              :key="subTotalIndex + '_sub_paid'"
            >
              {{
                item[subTotalValues[subTotal][0]] == null
                  ? ""
                  : "$ " + item[subTotalValues[subTotal][0]]
              }}
            </b-td>
            <b-td
              v-if="controls == (subTotal < 7 ? 0 : 1)"
              :key="subTotalIndex + '_sub_charge'"
            >
              {{
                item[subTotalValues[subTotal][1]] == null
                  ? ""
                  : "$ " + item[subTotalValues[subTotal][1]]
              }}
            </b-td>
          </template>

          <b-td>
            {{ item.sub_total_paid == null ? "" : "$ " + item.sub_total_paid }}
          </b-td>
          <b-td>
            {{
              item.sub_total_charge == null ? "" : "$ " + item.sub_total_charge
            }}
          </b-td>
        </template>
      </b-tr>

      <b-tr
        v-for="(item, index) in programs"
        :key="index + 'ft_third'"
        style="font-size: 14px; background: #1a2a21"
        class="text-center"
      >
        <template v-if="item.module_id == 3">
          <td>
            <div class="absolute-center">
              TOTAL
            </div>
          </td>

          <template v-for="(total, monthTotal) in 12">
            <td
              v-if="controls == (total < 7 ? 0 : 1)"
              :key="monthTotal + '_first_total'"
              colspan="2"
            >
              {{
                item[totalValues[total]] == null
                  ? ""
                  : "$ " + item[totalValues[total]]
              }}
            </td>
          </template>
          <td colspan="2">
            {{ item.total == null ? "" : "$ " + item.total }}
          </td>
        </template>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
export default {
  programs: {
    type: Array,
  },
  controls: {
    type: Boolean,
  },
  months: {
    type: Array,
  },
  subTotalValues: {
    type: Array,
  },
  totalValues: {
    type: Array,
  },
}
</script>

<style>
</style>
